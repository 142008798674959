<template>
  <div id="container">

    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h3 class="child_ttl">複眼経済塾の利用を再開します</h3>
          <p class="lead_txt alnC sp_alnL">
            おかえりなさい！
          </p>
          <p class="lead_txt alnC sp_alnL">
          本日より複眼経済塾の利用を再開します。
          </p>

          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerTop'}" class="submit basic">複眼経済塾トップ</router-link>
          </div>
          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerAccount'}" class="submit light skeleton">マイページ</router-link>
          </div>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link></li>
        <li>複眼経済塾の利用を再開しました</li>
      </ul>
    </div>

    <PaygentStatusModal v-if="paygent_status === 'status_waiting'" />
    <PaygentStatusFailedModal v-if="paygent_status === 'status_failed'" />

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import PaygentStatusFailedModal from "@/components/paygentStatusFailedModal.vue";
import PaygentStatusModal from "@/components/paygentStatusModal.vue";

export default {
  components: {PaygentStatusModal, PaygentStatusFailedModal},
  mixins: [Libraries],
  data() {
    return {
      paygent_status: 'status_paid'
    }
  },
  mounted() {
    // sync paygent status
    this.startPaygentStatusPolling((status) => {
      this.paygent_status = status
    });
  }
}
</script>
